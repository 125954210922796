/*
 * Important to understand that what is represented in the DOM (contenteditable) 
 * is not the model value, but the view value.
 * 
 * Updating the DOM value has consequences, such as the management of caret position.
 */
(function (canopyCore) {
	"use strict";

	canopyCore.directive("contenteditable", function($sce) {
		return {
			restrict: "A",
			require: "ngModel",
			link: function(scope, element, attrs, ngModel) {
				// Model to View
				ngModel.$render = () => {
					element.html($sce.getTrustedHtml(ngModel.$viewValue || ""));
				};

				// Initialise
				ngModel.$setViewValue(scope[attrs.ngModel]);
				ngModel.$render();

				// View to Model
				function writeToModel() {
					let html = scope.sanitiseHTML
						? scope.sanitiseHTML(element.html()) 
						: element.html();
					if (html === "<br/>" || html === "<br>") {
						html = "";
					}
					ngModel.$setViewValue(html);
				}

				function changeEvent() {
					scope.$evalAsync(writeToModel);
				}

				function pasteEvent($event) {
					if (scope.sanitisePaste) {
						$event.preventDefault();
						// Get the clipboard data
						const clipboardData = $event.originalEvent.clipboardData,
							textHtml = clipboardData.getData("text/html") || clipboardData.getData("text/plain");
						// Declare in parent directive
						scope.sanitisePaste(textHtml);
					}
					scope.$evalAsync(writeToModel);
				}

				function unsubscribeEvents() {
					element.off("blur keyup change", changeEvent);
					element.off("paste", pasteEvent);
				}

				element.on("blur keyup change", changeEvent);
				element.on("paste", pasteEvent);
				element.on("$destroy", unsubscribeEvents);
			}
		};
	});
})(canopyCore);