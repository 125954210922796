(function (canopyCore) {
	"use strict";

	canopyCore.directive("canopyCoreFieldDropdown", function (utilities, controllerLinker, $filter, canopyFormFields) {
		return {
			restrict: "A",
			replace: true,
			require: "^canopyCoreFormContext",
			controller: function ($scope, $element) {
				$scope.isVisibleOption = {
					checked: false,
					indeterminate: false
				};
				$scope.uuid = utilities.getUUID();
				$scope.validation = {
					active: false,
					passed: true,
					message: ""
				};
				$scope.selectedFieldOptions = [];
				$scope.dropdown = {
					open: false, 
					width: $element[0].clientWidth ? $element[0].clientWidth : undefined,
				};
				$scope.search = {};
				$scope.styles = {
					"no-margin": !$scope.fieldLabel && !$scope.fieldDescription,
					"form-control" : !$scope.fieldCustomStyles,
					"is-disabled": $scope.fieldDisabled || $scope.typeViewFieldDisabled
				};
				$scope.styles[$scope.fieldCustomStyles] =  $scope.fieldCustomStyles;

				$scope.currentPage = 0;
				$scope.pageSize;
				$scope.flattenedGroupOptions = [];
				$scope.numberOfPages = function() {
					if ($scope.fieldOptions) {
						return Math.ceil( $filter("filter")($scope.fieldOptions, $scope.filterFn).length / $scope.pageSize);                
					} else {
						return 0;
					}
				};
				$scope.getTitle = function(option) {
					if ($scope.fieldOptionsTitleProperty) {
						return utilities.getPropVal(option, $scope.fieldOptionsTitleProperty);
					} else if (option.title) {
						return option.title;
					} else {
						return option.value;
					}
				};
				$scope.getSubTitle = function (option) {
					if ($scope.fieldOptionsSubTitleProperty) {
						return utilities.getPropVal(option, $scope.fieldOptionsSubTitleProperty);
					}
				};
				$scope.reference = function () {
					return $scope.fieldLabel;
				};
				
				$scope.filterFn = function(option) {
					if (!$scope.search.searchFilterValue) {
						// no filtering provided
						return option;
					}
					
					var lowerCaseValue;
					var lowerCaseSearchValue = $scope.search.searchFilterValue.toLowerCase();
					var value = utilities.getPropVal(option, $scope.fieldOptionsTitleProperty) || option.title || option.value;
					if (value) {
						lowerCaseValue = value.toLowerCase();
					} else if (!$scope.fieldOptionsTitleProperty) {
						if (typeof option === "string") {
							lowerCaseValue = option.toLowerCase();
						}
					}

					if (!lowerCaseValue) {
						return;
					}

					if (lowerCaseValue.indexOf(lowerCaseSearchValue) !== -1) {
						return option;
					}
				};

				$scope.$watch("fieldOptions", function () {
					if ($scope.fieldOptionSelectionCallback) {
						return;
					}
					if ($scope.fieldOptions && $scope.fieldOptions.length) {
						$scope.pageSize = $scope.fieldOptions.length > 300 ? 40 : $scope.fieldOptions.length;

						if ($scope.fieldAutoSelect) {
							$scope.selectedFieldOptions.push($scope.fieldOptions[0]);
						} else if (typeof($scope.fieldAutoSelectIndex) !== "undefined") {
							if ($scope.fieldOptions[$scope.fieldAutoSelectIndex]) {
								$scope.selectedFieldOptions.push($scope.fieldOptions[$scope.fieldAutoSelectIndex]);
							}
						}
						if ($scope.isFormField && $scope.fieldMultiSelect) {
							$scope.fieldOptions.forEach(function(option) {
								if (option.selected) {
									var findSelected = $scope.selectedFieldOptions.findIndex(function (opt) {
										return opt[$scope.fieldOptionsValueProperty] === option[$scope.fieldOptionsValueProperty];
									});
									if (findSelected === -1) {
										$scope.selectedFieldOptions.push(option);
									}
								}
							});
						} else {
							$scope.fieldOptions.forEach(function(option) {
								if ($scope.fieldOptionsValueProperty) {
									if (option[$scope.fieldOptionsValueProperty] === $scope.fieldModel) {
										option.selected = true;
									}
								}

								if (option.selected && $scope.selectedFieldOptions.findIndex((selected) => angular.toJson(option) === angular.toJson(selected)) === -1) {
									$scope.selectedFieldOptions.push(option);
								}
							});
						}
						getFieldModel();
					}
				});

				const findOptionByProp = (data, prop) => {
					if (data[prop]) {
						return $scope.fieldOptions.find((option) => {
							return option[prop] === data[prop];
						});
					}
				};

				$scope.toggleOption = function(option) {
					if (!$scope.fieldDisabled && !$scope.typeViewFieldDisabled) {
						if ($scope.fieldOptionSelectionCallback) {
							$scope.callback(option);
						} else {
							var findSelected;
							if ($scope.isFormField && $scope.fieldMultiSelect) {
								findSelected = $scope.selectedFieldOptions.findIndex(function(opt) { return opt === option;});
								if (findSelected !== -1) {
									$scope.selectedFieldOptions.splice(findSelected, 1);
								} else {
									$scope.selectedFieldOptions.push(option);
								}
								option.selected = !option.selected;
							} else {
								findSelected = $scope.selectedFieldOptions.findIndex(function(selected) {
									return angular.toJson(option) === angular.toJson(selected);
								});
			
								if (findSelected !== -1) {
									$scope.selectedFieldOptions.splice(findSelected, 1);
									$scope.setAllVisibleOptionsCheckbox();
								} else {
									if (!$scope.fieldMultiSelect) {
										$scope.selectedFieldOptions = [];
									}
									$scope.selectedFieldOptions.push(option);
									if (!$scope.fieldMultiSelect) {
										$scope.fieldOptions.forEach((option) => option.selected = false);
										option.selected = true;
									}
									$scope.setAllVisibleOptionsCheckbox();
								}
							}
							getFieldModel();
						}
					}
				};

				function checkOption(option) {
					if ($scope.fieldOptionSelectionCallback) {
						$scope.callback(option);
					} else {
						const selectedFieldOption = $scope.selectedFieldOptions.find((fieldOption) => angular.toJson(fieldOption) === angular.toJson(option));
						if (!selectedFieldOption) {
							$scope.selectedFieldOptions.push(option);
						}
						$scope.fieldModel = $scope.selectedFieldOptions;
					}
				}

				function uncheckOption(option) {
					var selectedFieldOptionIndex = $scope.selectedFieldOptions.findIndex((fieldOption) => angular.toJson(option) === angular.toJson(fieldOption));
					if (selectedFieldOptionIndex !== -1) {
						$scope.selectedFieldOptions.splice(selectedFieldOptionIndex, 1);
					}
					$scope.fieldModel = $scope.selectedFieldOptions;
				}

				$scope.removeSelected = function(option, $event) {
					if ($scope.isFormField && !$scope.fieldMultiSelect) {
						$scope.selectedFieldOptions = [];
					} else {
						// I think an array find comparing the value might be simpler
						var findSelected = $scope.selectedFieldOptions.findIndex(function(selected) {
							return angular.toJson(option) === angular.toJson(selected);
						});
						if (findSelected !== -1) {
							$scope.selectedFieldOptions.splice(findSelected, 1);
							$scope.setAllVisibleOptionsCheckbox();
						}
						if ($scope.isFormField && $scope.fieldMultiSelect) {
							// this shouldn't work because the option passed in is a reference to the option in selectedFieldOptions not fieldOptions
							// the option here is also a reference to the option that has been removed above (in previous condition)
							//option.selected = false; 
							$scope.fieldOptions.find((fieldOption) => fieldOption.value === option.value).selected = false;
						}
					}
					getFieldModel();
					if ($event) {
						$event.stopPropagation();
					}
				};

				$scope.getSelected = function(option) {
					if ($scope.isFormField) {
						if (!$scope.fieldMultiSelect) {
							return $scope.selectedFieldOptions[0] && $scope.selectedFieldOptions[0].id === option.id;
						} else {
							if ($scope.selectedFieldOptions.length) {
								return $scope.selectedFieldOptions.find((opt) => angular.equals(opt, option));
							}
						}
					} else {
						var findSelected = $scope.selectedFieldOptions.findIndex(function(selected) {
							return angular.toJson(option) === angular.toJson(selected);
						});
						if (findSelected !== -1) {
							return true;
						}
					}
				};

				var getFieldModel = function () {
					if ($scope.isFormField) {
						if ($scope.isKeywordField) {
							$scope.fieldModel = $scope.selectedFieldOptions;
						} else {
							if ($scope.fieldMultiSelect) {
								concatSelectedOptions($scope.selectedFieldOptions);
							} else {
								$scope.fieldModel = $scope.selectedFieldOptions.length ? $scope.selectedFieldOptions[0][$scope.fieldOptionsValueProperty || "value"] : null;
							}
						}
					} else {
						if ($scope.fieldMultiSelect) {
							$scope.fieldModel = $scope.selectedFieldOptions;
						} else {
							$scope.fieldModel = $scope.selectedFieldOptions[0];
						}
					}
					if ($scope.fieldError) {
						$scope.validate();
					}
				};

				var initConverter = function () {
					var converterOptionsArray = [];
					$scope.fieldOptions.forEach(function(elm) {
						if (typeof elm === "string") {
							elm = {
								"value": elm,
								"selected": elm.selected ? elm.selected : false
							};
						} else {
							elm.selected = elm.selected ? elm.selected : false;
						}
						converterOptionsArray.push(elm);
						
					});
					$scope.fieldOptions = converterOptionsArray;
				};

				var concatSelectedOptions = function () {
					var selectedFieldOptionArray = [];
					$scope.selectedFieldOptions.forEach(function(string) {
						if ($scope.fieldOptionsValueProperty) {
							selectedFieldOptionArray.push(string[$scope.fieldOptionsValueProperty]);
						} else {
							selectedFieldOptionArray.push(string.value);
						}
					});
					var returnFieldObject = {};
					returnFieldObject["multi-select-options"] = $scope.fieldOptions;
					returnFieldObject["selected-options-concat"] = getConcatenatedSelectedOptions(selectedFieldOptionArray);
					$scope.$emit("dropdown-multi-selection", {value: returnFieldObject["selected-options-concat"]});
					$scope.fieldModel = returnFieldObject ? angular.toJson(returnFieldObject) : returnFieldObject;
				};

				var getConcatenatedSelectedOptions = function(selectedFieldOptionArray) {
					return $scope.fieldOptionsLabelKey ? selectedFieldOptionArray.map(function(option) { 
						return option[$scope.fieldOptionsLabelKey]; 
					}).join(", ") : selectedFieldOptionArray.join(", ");
				};
				
				var initOptions = function () {
					if ($scope.fieldOptionsGroupingProperty) {
						groupOptionsBy($scope.fieldOptionsGroupingProperty);
					}
				};

				var groupOptionsBy = function(groupBy) {
					$scope.flattenedGroupOptions = $scope.fieldOptions.reduce(function(groups, option) {
						var groupByValue = utilities.getPropVal(option, groupBy);
						var found = groups.find((group) => group.name === groupByValue);
						if (found) {
							found.groupOptions.push(option);
						} else {
							option.isGroupHeading = true;
							groups.push({name:groupByValue, groupOptions:[option]});
						}
						return groups;
					},[]).reduce((acc, group) => {
						group.groupOptions.forEach((option) => acc.push(option)); 
						return acc;
					}, []);
					$scope.pageSize = $scope.flattenedGroupOptions.length > 300 ? 40 : $scope.flattenedGroupOptions.length;
				};

				$scope.switchToDisable = function (element) {
					$scope.fieldDisabledOnSubmit = element ? true : false;
				};

				var validationFail = function() {
					$scope.validation.passed = false;
					$scope.validation.message = canopyFormFields.getFieldValidationMsg(canopyFormFields.FIELD_VALIDATION_MSG_TYPE_REQUIRED);
					$scope.fieldError = true;
				};

				$scope.validate = function () {
					$scope.validation.active = true;
					$scope.validation.passed = true;
					$scope.validation.message = "";
					$scope.fieldError = false;
					
					if ($scope.fieldRequired || $scope.typeViewFieldRequired) {
						if ($scope.fieldModel && (!$scope.fieldMultiSelect || ($scope.fieldMultiSelect && $scope.fieldModel.length !== 0))) {
							if (angular.isArray($scope.fieldModel) && $scope.fieldModel.length === 0) {
								validationFail();
							}
							else if (angular.isString($scope.fieldModel)) {
								try {
									const options = JSON.parse($scope.fieldModel)["multi-select-options"].filter((option) => option.selected === true);
									if (options.length === 0) {
										validationFail();
									}
								} catch (e) {
									console.error(e);
								}
							}
						} else {
							validationFail();
						}
					}

					if (!$scope.validation.passed && typeof $scope.onValidationFailed === "function") {
						$scope.onValidationFailed();
					}

					return $scope.validation.passed;
				};

				$scope.dropdownToggle = () => {
					$scope.dropdown.width = $element[0].clientWidth ? $element[0].clientWidth : undefined;

					$scope.search.searchFilterValue = null;
				};

				$scope.toggled = function() {
					$scope.$emit("dropdownOpenEvent", $scope.dropdown.open);
				};
				
				initOptions();

				$scope.$watch("fieldModel", function (newModel, oldModel) {
					if ($scope.validation.active) {
						$scope.validate();
					}
					
					if (!newModel && oldModel)  {
						$scope.fieldOptions.forEach((option) => option.selected = false);
						$scope.selectedFieldOptions = [];
					}
				});

				$scope.$watch("fieldDisabled", function () {
					$scope.styles["is-disabled"] = $scope.fieldDisabled;
				});

				$scope.$watch("fieldOptionsGroupingProperty", function (value) {
					if (value) {
						initOptions();
					}
				});

				$scope.$watch("isVisibleOption.checked", (isChecked, oldIsChecked) => {
					if ((isChecked !== oldIsChecked && oldIsChecked !== undefined) 
						&& (!$scope.isFormField && $scope.fieldMultiSelect)) {
						$scope.isVisibleOption.indeterminate = false;
						getPaginatedFieldOptions()
							.foreach((fieldOption) => {
								const optionSelected = $scope.selectedFieldOptions
									.find((selectedFieldOption) => angular.toJson(selectedFieldOption) === angular.toJson(fieldOption));
								if (isChecked) {
									if (!optionSelected)
										checkOption(fieldOption);
								} else {
									if (optionSelected)
										uncheckOption(fieldOption);
								}
							});
					}
				});

				$scope.setAllVisibleOptionsCheckbox = () => {
					if (!$scope.isFormField && $scope.fieldMultiSelect) {
						// Due to object mutations that can happen and the selecting deselecting of options these timeouts are currently required
						setTimeout(() => {
							const selectedFieldOptions = $scope.selectedFieldOptions.map((fieldOption) => angular.toJson(fieldOption)),
								paginatedFieldOptions = getPaginatedFieldOptions(),
								currentSelectedFieldOptions = paginatedFieldOptions
									.filter((fieldOption) => selectedFieldOptions.includes(angular.toJson(fieldOption)));
							
							// If this sets itself to false it will uncheck any currently selected options but we can select them again further down
							$scope.isVisibleOption.checked = paginatedFieldOptions.length 
								? paginatedFieldOptions
									.every((fieldOption) => selectedFieldOptions.includes(angular.toJson(fieldOption)))
								: false;

							setTimeout(() => {
								$scope.isVisibleOption.indeterminate = selectedFieldOptions.length && !$scope.isVisibleOption.checked
									? paginatedFieldOptions
										.some((fieldOption) => selectedFieldOptions.includes(angular.toJson(fieldOption)))
									: false;

								// Reapply any pre selected options
								setTimeout(() => {
									currentSelectedFieldOptions.foreach((fieldOption) => checkOption(fieldOption));
								});
							});
						});
					}
				};

				function getPaginatedFieldOptions() {
					const offset = $scope.currentPage * $scope.pageSize,
						limit = offset + $scope.pageSize;
					return $filter("filter")($scope.fieldOptions, $scope.filterFn)
						.filter((fieldOption, index) => index >= offset && index < limit);
				}

				$scope.callback = function (option) {
					$scope.fieldOptionSelectionCallback(option, $scope.fieldOptions);
				};

				var init = function () {
					if ($scope.isFormField &&
							$scope.fieldOptions && 
							$scope.fieldOptions.length && 
							$scope.fieldOptions[0].value === undefined && 
							$scope.fieldMultiSelect && !$scope.isKeywordField) {
						//only convert options if it comes from metadata not from field.value
						initConverter();
					} else {
						//need to get selected values
						$scope.fieldOptions.forEach(function(option) {
							if (option.selected && $scope.selectedFieldOptions.findIndex((selected) => angular.toJson(option) === angular.toJson(selected)) === -1) {
								$scope.selectedFieldOptions.push(option);
							}
						});
					}
					$scope.$watch("typeViewFieldNulled", function(val) {
						if (val) {
							resetDropdownField();
						}
					});
				};

				var resetDropdownField = function() {
					$scope.fieldOptions.forEach(function(option) {
						option.selected = false;
					});
					$scope.selectedFieldOptions = [];
					$scope.fieldModel = undefined;
				};
				
				if ($scope.events) {
					$scope.events.forEach(function(oEvent) {
						switch (oEvent.action) {
							case canopyFormFields.EVENT_REFRESH_DROPDOWN_OPTIONS:
								$scope.$on(oEvent.name, () => {
									initOptions();
								});
								break;
							case canopyFormFields.EVENT_SELECT_DROPDOWN_OPTION:
								$scope.$on(oEvent.name, (event, data) => {
									resetDropdownField();
									$scope.toggleOption(findOptionByProp(data, "id"));
								});
								break;
						}
					});
				}

				$scope.$watch("search.searchFilterValue", function() {
					$scope.currentPage = 0;
					if (!$scope.isFormField && $scope.fieldMultiSelect) {
						$scope.setAllVisibleOptionsCheckbox();
					}
				});

				$scope.getButton = function() {
					return $element[0].querySelector(".dropdown");
				};

				$scope.$on("$destroy", () => {
					$scope.$emit("canopyFieldDestroyed");
				});

				init();
			},
			scope: {
				fieldAllowSearch: "=",
				fieldAutoSelect: "=",
				fieldAutoSelectIndex: "=",
				fieldCustomStyles: "@",
				fieldDefaultText: "@", // placeholder
				fieldDescription: "@",
				fieldDisabled: "=",
				fieldDisplayPills: "=",
				fieldError: "=?",
				fieldHideCheckboxes: "=",
				fieldLabel: "@",
				fieldLineClamp: "=",
				fieldModel: "=",
				fieldMultiSelect: "=",
				fieldOptions: "=",
				fieldOptionSelectionCallback : "=",
				fieldOptionsGroupingProperty: "@",
				fieldOptionsSubTitleProperty: "@",
				fieldOptionsTitleProperty: "@",
				fieldOptionsValueProperty: "@",
				fieldRequired: "=",
				fieldSearchPlaceholder: "@",
				fieldTestId: "@",
				isFormField: "@",
				isKeywordField: "@",
				labelDecorator: "=",
				labelDecoratorVal: "=",
				onValidationFailed: "&?",
				typeViewFieldDisabled: "=",
				typeViewFieldHidden: "=",
				typeViewFieldNulled: "=",
				typeViewFieldRequired: "=",
				typeViewMessage: "=",
				collaborate: "<",
				events: "<",
				allowTooltip: "=?"
			},
			link: controllerLinker,
			templateUrl: Luma.paths.context + "/system/mantle/components/canopyCore/directives/form-field/canopy-form-field-dropdown.template.html"
		};
	});
})(canopyCore);