(function (canopyCore) {
	"use strict";

	canopyCore.directive("canopyCoreFieldMention", function ($sce, $timeout, $filter, controllerLinker, utilities, canopyFormFields, domPurifyService) {
		return {
			restrict: "A",
			replace: true,
			require: "^canopyCoreFormContext",
			controller: ($scope, $element) => {
				$scope.uuid = utilities.getUUID();

				$scope.validation = {
					passed: true,
					message: ""
				};

				$scope.reference = () => {
					return $filter("canopyLocalise")("core.field.input.comment.heading");
				};

				$scope.switchToDisable = (element) => {
					$scope.disabledOnSubmit = element ? true : false;
				};

				$scope.validate = () => {
					$scope.validation.passed = true;
					$scope.validation.message = "";

					if ($scope.required) {
						if (!$scope.model) {
							$scope.validation.passed = false;
							$scope.validation.message = canopyFormFields.getFieldValidationMsg(canopyFormFields.FIELD_VALIDATION_MSG_TYPE_REQUIRED);
						}
					}

					return $scope.validation.passed;
				};

				$scope.ui = {};

				$scope.onKeyup = ($event) => {
					const selection = window.getSelection(),
						key = $event.key;

					if (key === "@") {
						$scope.ui = {
							...$scope.ui,
							showMentions: true,
							mentionCaret: selection.anchorOffset,
							mentionIndex: 0,
							mentionPattern: "",
							mentions: [...$scope.mentionUsers],
						};
						// wait for popover to be rendered based on showMentions flag
						$timeout(positionPopover);

					} else if (key !== "ArrowUp" && key !== "ArrowDown") {
						const text = selection.anchorNode.textContent,
							caretChar = text[$scope.ui.mentionCaret - 1];
						if (caretChar === "@") {
							const mentionPattern = text.substring($scope.ui.mentionCaret, selection.focusOffset),
								filteredMentions = $scope.mentionUsers
									.filter((user) => user.name.toLowerCase().includes(mentionPattern.toLowerCase().trim()));
							$scope.ui = {
								...$scope.ui,
								showMentions: filteredMentions.length ? true : false,
								mentionIndex: 0,
								mentionPattern: mentionPattern,
								mentions: filteredMentions,
							};
						} else {
							// @ got removed
							$scope.ui.showMentions = false;
						}
					}
				};

				$scope.onKeydown = ($event) => {
					if ($scope.ui.mentions?.length) {
						switch ($event.key) {
							case "Enter": {
								if ($scope.ui.showMentions) {
									$event.preventDefault(); // Prevent newline
									$scope.addMention($scope.ui.mentions[$scope.ui.mentionIndex], $event);
								}
								break;
							}
							case "Escape": {
								$event.preventDefault();
								if ($scope.ui.showMentions) {
									$event.stopPropagation();
									$scope.ui.showMentions = false;
								}
								break;
							}
							case "ArrowUp": {
								if ($scope.ui.showMentions) {
									$event.preventDefault(); // Prevent scrolling
									$scope.ui.mentionIndex = ($scope.ui.mentionIndex - 1 + $scope.ui.mentions.length) % $scope.ui.mentions.length;
									scrollMentionItemIntoView($scope.ui.mentionIndex);
								}
								break;
							}
							case "ArrowDown": {
								if ($scope.ui.showMentions) {
									$event.preventDefault(); // Prevent scrolling
									$scope.ui.mentionIndex = ($scope.ui.mentionIndex + 1) % $scope.ui.mentions.length;
									scrollMentionItemIntoView($scope.ui.mentionIndex);
								}
								break;
							}
						}
					}
				};

				$scope.addMention = (user, $event) => {
					if ($event) {
						// Weird case, a contenteditable with divs/newlines will get
						// aa invalid selection range (starat of textarea) on click on the
						// popup list.
						// 
						// So, don't do regular event interpretation
						// And, use mousedown instead of click event handler
						$event.preventDefault();
						$event.stopPropagation();
					}

					if (user) {
						// Extend range to envelope @selection
						const range = window.getSelection().getRangeAt(0);
						range.setStart(range.endContainer, range.endOffset - $scope.ui.mentionPattern.length - 1);

						const mentionHtml = `<luma-mention class="mention" contentEditable="false" title="${user.email}" data-uuid="${user.uuid}">@${$sce.getTrustedHtml(user.name)}</luma-mention>`,
							htmlNode = htmlToElement(mentionHtml);
							
						insertMentionAtCursor(htmlNode.content.firstChild);

						$scope.dispatchChangeEvent();
					}
					$scope.ui = {
						...$scope.ui,
						mentionCaret: -1,
						mentionPattern: "",
						mentionIndex: 0,
						showMentions: false,
					};
				};

				$scope.dispatchChangeEvent = () => {
					const event = new CustomEvent("change", { bubbles: false }),
						editableDiv = angular.element($element).find("[contenteditable]")[0];
					editableDiv.dispatchEvent(event);
				};

				$scope.onBlur = () => {
					$timeout(() => ($scope.ui.showMentions = false), 200);
				};
			
				$scope.highlightUser = (index) => {
					$scope.ui.mentionIndex = index;
				};

				$scope.$on("$destroy", () => {
					$scope.$emit("canopyFieldDestroyed");
				});

				domPurifyService.addHook("uponSanitizeElement", (node, data) => {
					switch (data.tagName) {
						case "a": {
							// Replace the anchor node with the newly created text node
							if (node.parentNode) {
								// Create a new text node that contains the href value of the anchor tag
								const newText = document.createTextNode(node.getAttribute("href") || "");	
								node.parentNode.replaceChild(newText, node);
							}
							break;
						}
						case "br":
						case "div": {
							const allowedAttributes = [];
							node.getAttributeNames().forEach((attr) => {
								if (!allowedAttributes.includes(attr)) {
									node.removeAttribute(attr);
								}
							});
							break;
						}
						case "luma-mention": {
							node.setAttribute("contenteditable", "false");
							Array.from(node.childNodes).forEach(child => {
								if (child.nodeName === "BR") {
									node.removeChild(child);
								}
							});
							break;
						}
					}
				});

				$scope.sanitiseHTML = (html) => {
					return domPurifyService.sanitize(html, {
						ALLOWED_TAGS: ["div", "br"],
						FORBID_ATTR: ["style"],
						CUSTOM_ELEMENT_HANDLING: {
							tagNameCheck: /^luma-mention$/,
							attributeNameCheck: /^(data-uuid|title|contenteditable|class)$/, 
							allowCustomizedBuiltInElements: true,
						},
					})
					// Replace all &nbsp; with a space - xml validation schema is
					// not happy with &nbsp; in luma-mention
						.replace(/&nbsp;/g, " ")
					// Replace all <br> with <br/> - xml validation schema requires
					// xhtml
						.replace(/<br>|<br\s+>/g, "<br/>");
				};

				// This function is used by contenteditable.directive.js
				// which manages the paste event
				$scope.sanitisePaste = (paste) => {
					const cleanHtml = $scope.sanitiseHTML(paste);
					// Create a container element to parse the HTML content
					const element = htmlToElement(cleanHtml);
					insertPasteAtCursor(element.content);
				};

				function scrollMentionItemIntoView(index) {
					const items = angular.element($element).find(".mention-popover li");
					items[index].scrollIntoView({behavior: "smooth", block: "nearest"});
				}

				function positionPopover() {
					const mentionPopup = document.getElementById(`${$scope.uuid}-popover`);
					if (mentionPopup) {
						positionAtCursor(mentionPopup);
						positionWhenOffscreen(mentionPopup);
					}
				}

				function positionAtCursor(mentionPopup) {
					const selection = window.getSelection();
					if (selection.rangeCount > 0) {
						const range = selection.getRangeAt(0),
							rect = range.getBoundingClientRect(),
							parentRect = mentionPopup.offsetParent.getBoundingClientRect();
						mentionPopup.style.left = `${(rect.left - parentRect.left - 11)}px`;
						mentionPopup.style.top = `${(rect.top - parentRect.top) + rect.height}px`;
					}
				}

				function positionWhenOffscreen(mentionPopup) {
					const mentionPopupRight = mentionPopup.getBoundingClientRect().right,
						screenRight = window.innerWidth;
						
					if (mentionPopupRight > screenRight) {
						const offset = mentionPopupRight - screenRight;
						mentionPopup.style.left = `${(parseInt(mentionPopup.style.left, 10) - offset)}px`;
					}
				}

				function insertMentionAtCursor(htmlNode) {
					const selection = window.getSelection(),
						range = selection.getRangeAt(0),
						space = document.createTextNode("\u00a0");

					range.deleteContents(); 
					range.insertNode(htmlNode);
					range.setEndAfter(htmlNode);
					range.setStartAfter(htmlNode);
					range.insertNode(space);
					range.setEndAfter(space);
					range.setStartAfter(space);
					range.collapse(false);
					selection.removeAllRanges();
					selection.addRange(range);
				}

				function insertPasteAtCursor(htmlNode) {
					const selection = window.getSelection(),
						range = selection.getRangeAt(0);

					range.deleteContents();
					range.insertNode(htmlNode);
					range.collapse(false);
					selection.removeAllRanges();
					selection.addRange(range);
				}

				function htmlToElement(html) {
					const template = document.createElement("template");
					template.innerHTML = html.trim();
					return template;
				}
			},
			scope: {
				maxLength: "=",
				mentionUsers: "=",
				model: "=",
				placeholder: "@",
				required: "=",
			},
			link: controllerLinker,
			templateUrl: Luma.paths.context + "/system/mantle/components/canopyCore/directives/form-field/canopy-form-field-mention.template.html"
		};
	});
})(canopyCore);