(function(canopyCore) {
	"use strict";

	canopyCore.factory("domPurifyService", ["$window", ($window) => {

		function sanitize (html, config = {}) {
			return $window.DOMPurify.sanitize(html, config);
		}

		function addHook (hookName, hook) {
			$window.DOMPurify.addHook(hookName, hook);
		}

		return {
			sanitize: sanitize,
			addHook: addHook,
		};
	}]);
})(canopyCore);